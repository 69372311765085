import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerConfiguracion(type) {
    var url = ''
    switch (type) {
      case 'orders':
        url = ConfigAPI.baseURL + "orders-configuration" + Session.getToken();    
        break;
    
      default:
        break;
    }
    
    return instance.get(url);
  },  
  getResumen(type, data) {
    var params = data    
    var url = '' 
    switch (type) {
      case 'orders':
        url = ConfigAPI.baseURL + "orders/filter/resumen" + Session.getToken();    
        break;
    
      case 'sales':
        url = ConfigAPI.baseURL + "sales/filter/resumen" + Session.getToken();    
        break;        

      default:
        break;
    }
    return instance.post(url,params);
  },   
}

export default servicesAPI;