<template>
  <div>
    <orders v-if="types=='orders'" :filters="filters" />
    <sales v-if="types=='sales'" :filters="filters" />
  </div>
</template>
<script>    
  import Orders from './types/orders'  
  import Sales from './types/sales'  

  export default {
    props:{
      filters: {
        type: Object,
        default: null,
      },
      types: {
        type: String,
        default: null,
      },      
    },    
    components: {
      Orders,      
      Sales,      
    },     
  }
</script>